@import '../../../styles/propertySets.css';

:global(.image-gallery-thumbnails-container) {
  /*
   By default, the gallery controls the scroll position of the thumbnails when
   browsing the images. We change this logic to a freely scrollable container
   that the user controls. This overflow works together with the
   `disableThumbnailScroll` option in the component JS.
   */
  overflow: auto;
  text-align: left !important;
}
:global(.image-gallery-thumbnails) {
  padding-top: 24px;
  padding-bottom: 0;
}
:global(.image-gallery-thumbnail) {
  width: auto;
  border-radius: 10px;

  text-align: left !important;

  &:first-child {
    margin-left: 24px;

    @media (--viewportMedium) {
      margin-left: 0;
    }
  }
  &:last-child {
    margin-right: 24px;

    @media (--viewportMedium) {
      margin-right: 0;
    }
  }

  &:hover,
  &:global(.active) {
    border-width: 2px;
    border-color: var(--fave-color-purple-s500);
  }
}

:global(.image-gallery-slide-wrapper) {
  background-color: var(--matterColorLight);

  @media (--viewportMedium) {
    border-radius: 14px;
    border: 1px solid var(--fave-color-purple-s50);
  }
}

:global(.fullscreen) {
  background-color: var(--matterColorLight) !important;

  & :global(.image-gallery-slide-wrapper) {
    background-color: transparent;
    border: none;
  }

  & :global(.image-gallery-thumbnails-container),
  & :global(.image-gallery-thumbnail) {
    text-align: center !important;
  }
}

.root {
}

.itemWrapper,
.itemWrapperFullscreen {
  display: flex;
  align-items: center;
  justify-content: center;

}

.itemWrapper {
  max-height: calc(100vh - 200px);
}

.noImage {
  border: 1px solid var(--matterColorNegative);
  border-radius: var(--borderRadius);
}

.itemWrapperFullscreen {
  height: calc(100vh - 130px);
}

.itemCentering {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.item {
  max-width: 100%;
  max-height: 100%;
  border-radius: var(--borderRadius);
}

.thumb {
  /* With the active thumbnail border (default 4px), make the result
   height a multiple of the baseline. */
  max-width: 136px;
  max-height: 136px;
  border-radius: var(--borderRadius);
}

.navLeft,
.navRight {
  position: absolute;
  width: 60px;
  height: 100%;
  border: 0;
  padding: 0;
  z-index: 1;

  /* center content */
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;

    & .navArrowWrapper {
      background-color: rgba(255, 255, 255, 1);
    }
  }
}

.navLeft {
  left: 0;

  & .navArrowWrapper {
    padding-right: 2px;
  }
}

.navRight {
  right: 0;

  & .navArrowWrapper {
    padding-left: 2px;
  }
}

.navArrowWrapper {
  display: none;

  width: 40px;
  height: 40px;
  border-radius: 16px;

  background-color: rgba(255, 255, 255, 0.5);

  @media (--viewportLarge) {
    /* center content */
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.openFullscreen {
  /* Hide on mobile. Currently react-image-gallery fullscreen mode is broken on
     mobile, and mobile doesn't really benefit from fullscreen anyways. */
  display: none;

  /** marketplaceButtonFontStyles */
    font-family: 'Proxima Nova', Helvetica, Arial, sans-serif;
    font-weight: var(--fontWeightSemiBold);
    font-size: 14px;
    letter-spacing: 0.2px;
    line-height: 24px;
/** end */
  font-weight: var(--fontWeightBold);

  /* Position and dimensions */
  position: absolute;
  bottom: 19px;
  right: 24px;
  margin: 0;
  padding: 8px 13px 6px 13px;

  /* Colors */
  background-color: var(--matterColorLight);

  /* Borders */
  border: 1px solid var(--matterColorNegative);
  border-radius: var(--borderRadius);

  cursor: pointer;
  transition: var(--transitionStyleButton);

  /* Ensure the button is on top of the navRight area */
  z-index: 1;

  &:hover {
    background-color: var(--marketplaceColor);
    border-color: var(--marketplaceColor);
    color: var(--matterColorLight);
  }

  @media (--viewportLarge) {
    display: block;
  }
}

.close {
  /**marketplaceModalCloseStyles*/  
   /* Position inside modal */
    position: fixed;
    top: 0;
    right: 0;

    /* Some content (like map) is positioning itself on top of close button without z-index handling */
    z-index: calc(var(--zIndexModal) + 1);

    /* Safari didn't know how to baseline-align svg icon and text */
    display: flex;
    align-items: flex-start;
    width: auto;

    /* Sizing (touch area) */
    padding: 24px;
    margin: 0;
    border: 0;

    @media (--viewportMedium) {
      padding: 27px 30px;
      position: absolute;
    }

    /* Colors */
    background-color: transparent;
    color: var(--matterColor);

    cursor: pointer;

    &:enabled:hover {
      background-color: transparent;
      color: var(--matterColorDark);
      box-shadow: none;
      text-decoration: none;
    }
    &:enabled:active {
      background-color: transparent;
      color: var(--matterColorDark);
    }
    &:disabled {
      background-color: transparent;
    }
/** end */

  color: var(--matterColorAnti);
  transition: var(--transitionStyleButton);

  &:enabled:hover,
  &:enabled:active {
    color: var(--matterColorDark);
  }
}

.closeText {
  /**marketplaceModalCloseText*/  
   /* Font */
    /**marketplaceH6FontStyles*/  
 font-weight: var(--fontWeightBold);
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
    margin-top: 10px;
    margin-bottom: 8px;

    @media (--viewportMedium) {
      line-height: 16px;
      /* margin-top + n * line-height + margin-bottom => x * 8px */
      margin-top: 10px;
      margin-bottom: 6px;
    }
/** end */
    margin: 0;

    @media (--viewportMedium) {
      margin: 0;
    }
/** end */
}

.closeIcon {
  /**marketplaceModalCloseIcon*/  
     display: inline-block;
    margin-left: 8px;

    /* Move X icon vertically to align it with the close text. */
    margin-top: 3px;

    box-sizing: content-box;

    @media (--viewportMedium) {
      margin-top: 2px;
    }
/** end */
}

.thumbnailsContainer{
overflow-x: scroll;
margin-top: 6px;
margin-bottom: 6px;
display: flex;

}

.selectedThumbnail {
  border: 2px   solid var(--marketplaceColor);
border-radius: var(--borderRadius); 
margin: 6px;
}
.unselectedThumbnail {
border-radius: var(--borderRadius); 
margin: 8px;

}