@import '../assets/sanitize.css';
@import './customMediaQueries.css';
@import './faveStyles.css';
@import './propertySets.css';

/* ================ Custom Properties aka CSS variables ================ */

/**
 * These variables are available in global scope through ":root"
 * element (<html> tag). You can use Custom Properties to achieve
 * more dynamic theming by changing the value on the fly with JS:
 * document.documentElement.style.setProperty("--marketplaceColor", '#55AA55');
 *
 * Read more about how to use CSS Custom Properties
 * https://developer.mozilla.org/en-US/docs/Web/CSS/Using_CSS_custom_properties
 */
@font-face {
  font-family: 'Proxima Nova';
  src: url('/src/assets/fonts/Proxima-Nova-Regular.otf');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('/src/assets/fonts/Proxima-Nova-Bold.otf');
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: 'Greycliff';
  src: url('/src/assets/fonts/Greycliff-ExtraBold.otf');
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: 'Permanent Marker';
  src: url('/src/assets/fonts/PermanentMarker-Regular.ttf');
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}

:root {
  /* ================ Colors ================ */
  --fave-copy-default-regular {
    font-family: Proxima Nova;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
  }

  --marketplaceColor: #5d2bc0; /* hsl(260, 63%, 46%) */
  --marketplaceColorLight: #7748d5; /* hsl(260, 63%, 56%), +10L from marketplaceColor */
  --marketplaceColorDark: #34196c; /* hsl(260, 63%, 26%), -20L from marketplaceColor */

  /* Used with inline CSS SVGs */
  --marketplaceColorEncoded: %23a02cbf;

  --successColor: #2ecc71;
  --successColorDark: #239954;
  --successColorLight: #f0fff6;
  --failColor: var(--fave-color-error-s900);
  --failColorLight: #fff0f0;
  --attentionColor: #ffaa00;
  --attentionColorLight: #fff7f0;
  --bannedColorLight: var(--marketplaceColorLight);
  --bannedColorDark: var(--marketplaceColor);

  --matterColorDark: #000000;
  --matterColor: #000000;
  --matterColorAnti: #b2b2b2;
  --matterColorNegative: #e7e7e7;
  --matterColorBright: #fcfcfc;
  --matterColorLight: #ffffff;

  --matterColorLightTransparent: rgba(255, 255, 255, 0.65);

  /* ================ Font ================ */
  --fontFamily: 'Proxima Nova';

  --fontWeightRegular: 400;
  --fontWeightMedium: 500;
  --fontWeightSemiBold: 600;
  --fontWeightBold: 700;

  --fontWeightHighlightEmail: var(--fontWeightBold);

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */
  --spacingUnit: 6px;
  --spacingUnitDesktop: 8px;

  /* Shadows */
  --boxShadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  --boxShadowTop: 0 -2px 4px 0 rgba(0, 0, 0, 0.1);
  --boxShadowLight: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  --boxShadowPopup: 0px 0px 11px 0px #0000001C;
  --boxShadowPopupLight: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
  --boxShadowBreakdown: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
  --boxShadowButton: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
  --boxShadowListingCard: 0 0 50px 0 rgba(0, 0, 0, 0.1);
  --boxShadowNotFoundPageSearch: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  --boxShadowSectionLocationHover: 0 10px 30px 0 rgba(0, 0, 0, 0.1);
  --boxShadowBottomForm: 0 -2px 4px 0 rgba(0, 0, 0, 0.05);
  --boxShadowFilterButton: 0 4px 4px 0 rgba(0, 0, 0, 0.2);

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  --zIndexTopbar: 10;
  /* small popups on UI should use z-indexes above 50 */
  --zIndexPopup: 50;
  /* modals and UI overlays should use z-indexes above 100 */
  --zIndexModal: 100;
  /* generic error message should overlay even modals */
  --zIndexGenericError: 200;

  /* ================ Border radius ================ */

  --borderRadius: 14px;
  --borderRadiusMobileSearch: 3px;

  /* ================ Transition styles ================ */

  --transitionStyle: ease-in 0.2s;
  --transitionStyleButton: ease-in-out 0.1s;

  /* ================ Topbar related ================ */

  --topbarHeight: 60px;
  --topbarHeightDesktop: 72px;

  --TopbarMobileMenu_topMargin: 96px;

  --Topbar_logoHeight: 36px;
  --CheckoutPage_logoHeight: 25px;
  --CheckoutPage_logoHeightDesktop: 27px;

  --TopbarSearchForm_inputHeight: 53px;
  --TopbarSearchForm_topbarMargin: 94px;
  --TopbarSearchForm_bottomBorder: 3px;

  /* ================ Modal default padding ================ */

  --modalPadding: 24px 24px 48px 24px;
  --modalPaddingMedium: 55px 60px 55px 60px;

  /* ================ Filters ================ */
  --marketplaceButtonSmallDesktopPadding: 9px 16px 9px 16px;

  /* ================ DateInput, DateRangeInput, DateRangeController ================ */
  --ReactDates_selectionHeight: 36px;
  --ReactDates_hoveredOverlayColor: rgba(255, 255, 255, 0.2);

  --DateInput_selectionHeight: var(--ReactDates_selectionHeight);
  --DateInput_hoveredOverlayColor: var(--ReactDates_hoveredOverlayColor);
  --DateRangeInput_selectionHeight: var(--ReactDates_selectionHeight);
  --DateRangeInput_hoveredOverlayColor: var(--ReactDates_hoveredOverlayColor);
  --DateRangeController_selectionHeight: var(--ReactDates_selectionHeight);

  /* ================ SectionHero ================ */

  --SectionHero_desktopTitleMaxWidth: 625px;

  /* ================ TabNav ================ */
  --TabNav_linkWidth: 240px;

  /* ================ LandingPage ================ */
  --LandingPage_sectionMarginTop: 40px;
  --LandingPage_sectionMarginTopMedium: 60px;
  --LandingPage_sectionMarginTopLarge: 94px;

  /* ================ EditListingAvailabilityForm, ManageAvailabilityCalendar ================ */
  --ManageAvailabilityCalendar_gridColor: #e0e0e0;
  --ManageAvailabilityCalendar_availableColor: #ffffff;
  --ManageAvailabilityCalendar_availableColorHover: #fafafa;
  --ManageAvailabilityCalendar_blockedColor: #ebebeb;
  --ManageAvailabilityCalendar_blockedColorHover: #e6e6e6;
  --ManageAvailabilityCalendar_reservedColor: #e6fff0;
  --ManageAvailabilityCalendar_reservedColorHover: #e1faeb;
  --ManageAvailabilityCalendar_failedColor: #fff2f2;

  /* ================ ProfileSettingsForm ================ */
  --ProfileSettingsForm_avatarSize: 96px;
  --ProfileSettingsForm_avatarSizeDesktop: 240px;
}

/* ================ Global element styles ================ */

body {
  font-family: 'Proxima Nova';
  -webkit-font-smoothing: antialiased; /* http://szafranek.net/blog/2009/02/22/font-smoothing-explained/ */
  -moz-osx-font-smoothing: grayscale; /* http://szafranek.net/blog/2009/02/22/font-smoothing-explained/ */
  text-rendering: optimizeSpeed;

  background-color: var(--matterColorBright);
}

::selection {
  background: var(--marketplaceColor); /* WebKit/Blink Browsers */
  color: var(--matterColorLight);
}

::-moz-selection {
  background: var(--marketplaceColor); /* Gecko Browsers */
  color: var(--matterColorLight);
}

a {
  /** marketplaceLinkStyles */
 /* Position and dimensions */
    display: inline;
    margin: 0;
    padding: 0;

    /* Borders */
    border: none;

    /* Colors */
    color: var(--marketplaceColor);

    /* Text size should be inherited */
    text-decoration: none;

    &:hover {
      text-decoration: none;
      cursor: pointer;
    }
/** end */
}

h1 {
  /** marketplaceH1FontStyles*/  
 font-family: 'Greycliff';
    font-size: 40px;
    font-weight: 800;
    line-height: 46px;
    letter-spacing: -0.01em;
/** end */
}
h2 {
  /** marketplaceH1FontStyles*/  
    font-family: 'Greycliff';
    font-size: 32px;
    font-weight: 700;
    line-height: 37px;
    letter-spacing: -1px;
/** end */
}
h3 {
  /** marketplaceH3FontStyles*/  
font-family: 'Greycliff';
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: -0.5px;
/** end */
}
h4 {
  /**--marketplaceH4FontStyles*/  
  font-family: 'Greycliff';
    font-size: 20px;
    font-weight: 700;
    line-height: 23px;
    letter-spacing: -0.4000000059604645px;
/** end */
}
h5 {
  /**marketplaceH5FontStyles */
    font-family: 'Greycliff';
    font-size: 16px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0em;
/** end */
}
h6 {
  /**marketplaceH6FontStyles*/  
 font-weight: var(--fontWeightBold);
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
    margin-top: 10px;
    margin-bottom: 8px;

    @media (--viewportMedium) {
      line-height: 16px;
      /* margin-top + n * line-height + margin-bottom => x * 8px */
      margin-top: 10px;
      margin-bottom: 6px;
    }
/** end */
}

li {
  /**marketplaceDefaultFontStyles*/
 font-family: var(--fontFamily);
    font-weight: var(--fontWeightMedium);
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.1px;
    /* No margins for default font */

    @media (--viewportMedium) {
      font-size: 16px;
      line-height: 32px;
    }
/** end */
}

p,
pre {
  /**marketplaceBodyFontStyles*/
/** fave-copy-default-regular */
  font-family: Proxima Nova;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
/** end */
    /* margin-top + n * line-height + margin-bottom => x * 6px */
    margin-top: 12px;
    margin-bottom: 12px;

    @media (--viewportMedium) {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
      margin-top: 16px;
      margin-bottom: 16px;
    }
/** end */
    
/** end */
}

/* ================ Normalisations ================ */

html {
  /**marketplaceDefaultFontStyles*/
 font-family: var(--fontFamily);
    font-weight: var(--fontWeightMedium);
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.1px;
    /* No margins for default font */

    @media (--viewportMedium) {
      font-size: 16px;
      line-height: 32px;
    }
/** end */
  color: var(--matterColor);
  padding: 0;
  margin: 0;
}

ul {
  list-style: none;
  padding: 0;
}

legend,
label {
  /** fave-copy-small-regular */  
 font-family: Proxima Nova;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.2px;
/** end */
  display: block;
  position: relative;
  top: 9px;
  background-color: white;
  width: fit-content;
  padding-left: 2px;
  padding-right: 2px;
  border-color: var(--fave-color-black-s10);
  left: 16px;
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

button {
  font-family: 'Proxima Nova';
}

select {
  /**marketplaceSelectStyles*/
 font-family: Proxima Nova;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
/** end */
    display: block;
    width: 100%;
    margin: 0;
    padding: 8px 16px 8px 24px;
    background-color: var(--color-white-pure);

    /* Unset user agent styles */
    appearance: none;

    /* Borders */
    border: 1px solid var(--fave-color-black-s50);
    border-radius: var(--borderRadius);

    /* Background */
    background-image: url('data:image/svg+xml;utf8,<svg width="14" height="9" viewBox="0 0 14 9" xmlns="http://www.w3.org/2000/svg"><path d="M6.53 7.472c.26.26.68.26.94 0l5.335-5.333c.26-.263.26-.684 0-.944-.26-.26-.683-.26-.943 0L7 6.056l-4.862-4.86c-.26-.26-.683-.26-.943 0-.26.26-.26.68 0 .943L6.53 7.47z" stroke="%234A4A4A" fill="%234A4A4A" fill-rule="evenodd"/></svg>');
    background-size: 16px 24px;
    background-position: bottom 50% right 11px;

    /* Effects */

    cursor: pointer;
    transition: border-color var(--transitionStyle);

    &:hover,
    &:focus {
      border-color: var(--fave-color-primary-s500);
      outline: none;
    }

    &:disabled {
      color: var(--fave-color-black-s100);
      border-color: var(--fave-color-black-s50);
      padding: 8px 16px 8px 16px;

      cursor: not-allowed;
    }

    @media (--viewportMedium) {
      padding: 8px 16px 8px 16px;
    }
    /* No margins for default font */

    @media (--viewportMedium) {
      font-size: 16px;
      line-height: 32px;
    }
/** end */
  /** marketplaceInputStyles */
 font-family: Proxima Nova;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
/** end */
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.1px;
    /* No margins for default font */

    @media (--viewportMedium) {
      font-size: 16px;
      line-height: 32px;
    }
/** end */
}

input {
  /** marketplaceInputStyles */
 /* Dimensions */
    display: block;
    width: 100%;
    margin: 0;
    padding: 8px 16px 8px 16px;

    /* Borders */
    border: 1px solid var(--fave-color-black-s50);
    border-radius: var(--borderRadius);
    background-color: var(--color-white-pure);

    &::placeholder {
      /** fave-copy-default-regular */
  font-family: Proxima Nova;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
/** end */
      color: var(--matterColorAnti);
    }

    /* Effects */

    transition: border-color var(--transitionStyle);

    &:hover,
    &:focus {
      border-color: var(--fave-color-primary-s500);
      outline: none;
     
    }

    &:disabled {
      color: var(--fave-color-black-s100);
      border-color: var(--fave-color-black-s50);
      cursor: not-allowed;
    }

    @media (--viewportMedium) {
      padding: 8px 16px 8px 16px;
    }
/** end */
  /** marketplaceInputFontStyles */
 /**fave-copy-default-bold*/  
  font-family: Proxima Nova;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
/** end */
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.1px;
    /* No margins for default font */

    @media (--viewportMedium) {
      font-size: 16px;
      line-height: 32px;
    }
/** end */
}

textarea {
  /* /** marketplaceInputStyles */
 /* Dimensions */
    display: block;
    width: 100%;
    margin: 0;
    padding: 8px 16px 8px 16px;

    /* Borders */
    border: 1px solid var(--fave-color-black-s50);
    border-radius: var(--borderRadius);
    background-color: var(--color-white-pure);

    &::placeholder {
      /** fave-copy-default-regular */
  font-family: Proxima Nova;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
/** end */
      color: var(--matterColorAnti);
    }

    /* Effects */

    transition: border-color var(--transitionStyle);

    &:hover,
    &:focus {
      border-color: var(--fave-color-primary-s500);
      outline: none;
     
    }

    &:disabled {
      color: var(--fave-color-black-s100);
      border-color: var(--fave-color-black-s50);
      cursor: not-allowed;
    }

    @media (--viewportMedium) {
      padding: 8px 16px 8px 16px;
    }
/** end */ 
  /** marketplaceInputFontStyles */
 /**fave-copy-default-bold*/  
  font-family: Proxima Nova;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
/** end */
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.1px;
    /* No margins for default font */

    @media (--viewportMedium) {
      font-size: 16px;
      line-height: 32px;
    }
/** end */

  /* Todo: All textareas are using auto-sizing extension which currently calculates required space incorrectly when box-sixing is "border-box" */
  box-sizing: content-box;
  width: 100%;
  margin: 0;
  padding: 0;
  border-width: 0;
  outline: none;
}

.fontsLoaded {
  & body {
    font-family: 'Proxima Nova', Helvetica, Arial, sans-serif;
  }
  & button {
    font-family: 'Proxima Nova', Helvetica, Arial, sans-serif;
  }
}



.react-international-phone-country-selector {
  position: relative;
}
.react-international-phone-country-selector-button {
  display: flex;
  height: var(--react-international-phone-height, 36px);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  padding: 0;
  border: 1px solid
    var(
      --react-international-phone-country-selector-border-color,
      var(--react-international-phone-border-color, gainsboro)
    );
  margin: 0;
  appearance: button;
  -webkit-appearance: button;
  background-color: var(
    --react-international-phone-country-selector-background-color,
    var(--react-international-phone-background-color, white)
  );
  cursor: pointer;
  text-transform: none;
  user-select: none;
}
.react-international-phone-country-selector-button:hover {
  background-color: var(
    --react-international-phone-country-selector-background-color-hover,
    whitesmoke
  );
}
.react-international-phone-country-selector-button--hide-dropdown {
  cursor: auto;
}
.react-international-phone-country-selector-button--hide-dropdown:hover {
  background-color: transparent;
}
.react-international-phone-country-selector-button__button-content {
  display: flex;
  align-items: center;
  justify-content: center;
}
.react-international-phone-country-selector-button__flag-emoji {
  margin: 0 4px;
}
.react-international-phone-country-selector-button__flag-emoji--disabled {
  opacity: 0.75;
}
.react-international-phone-country-selector-button__dropdown-arrow {
  border-top: var(--react-international-phone-country-selector-arrow-size, 4px)
    solid var(--react-international-phone-country-selector-arrow-color, #777);
  border-right: var(
      --react-international-phone-country-selector-arrow-size,
      4px
    )
    solid transparent;
  border-left: var(--react-international-phone-country-selector-arrow-size, 4px)
    solid transparent;
  margin-right: 4px;
  transition: all 0.1s ease-out;
}
.react-international-phone-country-selector-button__dropdown-arrow--active {
  transform: rotateX(180deg);
}
.react-international-phone-country-selector-button__dropdown-arrow--disabled {
  border-top-color: var(
    --react-international-phone-disabled-country-selector-arrow-color,
    #999
  );
}
.react-international-phone-country-selector-button--disabled {
  background-color: var(
    --react-international-phone-disabled-country-selector-background-color,
    var(--react-international-phone-disabled-background-color, whitesmoke)
  );
  cursor: auto;
}
.react-international-phone-country-selector-button--disabled:hover {
  background-color: var(
    --react-international-phone-disabled-country-selector-background-color,
    var(--react-international-phone-disabled-background-color, whitesmoke)
  );
}
.react-international-phone-flag-emoji {
  width: var(--react-international-phone-flag-width, 24px);
  height: var(--react-international-phone-flag-height, 24px);
  box-sizing: border-box;
}
.react-international-phone-country-selector-dropdown {
  position: absolute;
  z-index: 1;
  top: var(--react-international-phone-dropdown-top, 44px);
  left: var(--react-international-phone-dropdown-left, 0);
  display: flex;
  width: 300px;
  max-height: 200px;
  flex-direction: column;
  padding: 4px 0;
  margin: 0;
  background-color: var(
    --react-international-phone-dropdown-item-background-color,
    var(--react-international-phone-background-color, white)
  );
  box-shadow: var(
    --react-international-phone-dropdown-shadow,
    2px 2px 16px rgba(0, 0, 0, 0.25)
  );
  color: var(
    --react-international-phone-dropdown-item-text-color,
    var(--react-international-phone-text-color, #222)
  );
  list-style: none;
  overflow-y: scroll;
}
.react-international-phone-country-selector-dropdown__preferred-list-divider {
  height: 1px;
  border: none;
  margin: var(
    --react-international-phone-dropdown-preferred-list-divider-margin,
    0
  );
  background: var(
    --react-international-phone-dropdown-preferred-list-divider-color,
    var(--react-international-phone-border-color, gainsboro)
  );
}
.react-international-phone-country-selector-dropdown__list-item {
  display: flex;
  min-height: var(--react-international-phone-dropdown-item-height, 28px);
  box-sizing: border-box;
  align-items: center;
  padding: 2px 8px;
}
.react-international-phone-country-selector-dropdown__list-item-flag-emoji {
  margin-right: 8px;
}
.react-international-phone-country-selector-dropdown__list-item-country-name {
  overflow: hidden;
  margin-right: 8px;
  font-size: var(--react-international-phone-dropdown-item-font-size, 14px);
  text-overflow: ellipsis;
  white-space: nowrap;
}
.react-international-phone-country-selector-dropdown__list-item-dial-code {
  color: var(--react-international-phone-dropdown-item-dial-code-color, gray);
  font-size: var(--react-international-phone-dropdown-item-font-size, 14px);
}
.react-international-phone-country-selector-dropdown__list-item:hover {
  background-color: var(
    --react-international-phone-selected-dropdown-item-background-color,
    var(
      --react-international-phone-selected-dropdown-item-background-color,
      whitesmoke
    )
  );
  cursor: pointer;
}
.react-international-phone-country-selector-dropdown__list-item--selected,
.react-international-phone-country-selector-dropdown__list-item--focused {
  background-color: var(
    --react-international-phone-selected-dropdown-item-background-color,
    whitesmoke
  );
  color: var(
    --react-international-phone-selected-dropdown-item-text-color,
    var(--react-international-phone-text-color, #222)
  );
}
.react-international-phone-country-selector-dropdown__list-item--selected
  .react-international-phone-country-selector-dropdown__list-item-dial-code,
.react-international-phone-country-selector-dropdown__list-item--focused
  .react-international-phone-country-selector-dropdown__list-item-dial-code {
  color: var(
    --react-international-phone-selected-dropdown-item-dial-code-color,
    var(--react-international-phone-dropdown-item-dial-code-color, gray)
  );
}
.react-international-phone-country-selector-dropdown__list-item--focused {
  background-color: var(
    --react-international-phone-selected-dropdown-item-background-color,
    var(
      --react-international-phone-selected-dropdown-item-background-color,
      whitesmoke
    )
  );
}
.react-international-phone-dial-code-preview {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 8px;
  border: 1px solid
    var(
      --react-international-phone-dial-code-preview-border-color,
      var(--react-international-phone-border-color, gainsboro)
    );
  margin-right: -1px;
  background-color: var(
    --react-international-phone-dial-code-preview-background-color,
    var(--react-international-phone-background-color, white)
  );
  color: var(
    --react-international-phone-dial-code-preview-text-color,
    var(--react-international-phone-text-color, #222)
  );
  font-size: var(
    --react-international-phone-dial-code-preview-font-size,
    var(--react-international-phone-font-size, 13px)
  );
}
.react-international-phone-dial-code-preview--disabled {
  background-color: var(
    --react-international-phone-dial-code-preview-disabled-background-color,
    var(--react-international-phone-disabled-background-color, whitesmoke)
  );
  color: var(
    --react-international-phone-dial-code-preview-disabled-text-color,
    var(--react-international-phone-disabled-text-color, #666)
  );
}
.react-international-phone-input-container {
  display: flex;
}
.react-international-phone-input-container
  .react-international-phone-country-selector-button {
  border-radius: var(--react-international-phone-border-radius, 4px);
  margin-right: -1px;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
.react-international-phone-input-container .react-international-phone-input {
  overflow: visible;
  height: var(--react-international-phone-height, 36px);
  box-sizing: border-box;
  padding: 0 8px;
  border: 1px solid var(--react-international-phone-border-color, gainsboro);
  border-radius: var(--react-international-phone-border-radius, 4px);
  margin: 0;
  background-color: var(--react-international-phone-background-color, white);
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  color: var(--react-international-phone-text-color, #222);
  font-family: inherit;
  font-size: var(--react-international-phone-font-size, 13px);
}
.react-international-phone-input-container
  .react-international-phone-input:focus {
  outline: none;
}
.react-international-phone-input-container
  .react-international-phone-input--disabled {
  background-color: var(
    --react-international-phone-disabled-background-color,
    whitesmoke
  );
  color: var(--react-international-phone-disabled-text-color, #666);
}
